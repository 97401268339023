<template>
  <div class="weixin_login">
    <div class="box">
      <div class="top">
        <div class="back" @click="$router.go(-1)">
          <img :src="require('assets/images/goldenSentence/back.png')" alt="" />
          返回
        </div>
      </div>

      <div class="content">
        <img
          v-if="!isinp_box"
          class="weixin_logo"
          :src="require('assets/images/login/weixin_logo.png')"
          alt=""
        />

        <div class="box" v-if="isinp_box">
          <div class="title">请绑定手机号</div>

          <div class="inp_box">
            <img
              :src="require('assets/images/login/login-yonghu.png')"
              alt=""
            />
            <input
              type="text"
              name="mobile"
              maxlength="11"
              @input="phone = phone.replace(/[^0-9]/g, '')"
              v-model="phone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="inp_box">
            <img
              :src="require('assets/images/login/login-codeico.png')"
              alt=""
            />
            <input
              type="text"
              name="mobile"
              maxlength="6"
              v-model="authCode"
              placeholder="请输入验证码"
            />

            <div class="span" @click="sendVerif" v-show="verif_seconds == 60">
              获取验证码
            </div>
            <div class="span" v-show="verif_seconds < 60">
              已发送({{ verif_seconds }})
            </div>
          </div>

          <div class="btn" @click="binding">绑定</div>
        </div>
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>
<script>
import commonFooter from "components/footer/commonFooter.vue";
import Cookies from "js-cookie";
import { getMobileAchCaptchas, checkMobileAchCaptchas } from "api/common";
import { weixinLogin, verify, getuserInfo } from "api/service";
import qs from "qs";
export default {
  name: "AuthRedirect",
  components: {
    commonFooter,
  },
  data() {
    return {
      // 微信扫码code
      code: "",
      // 手机号
      phone: "",
      // 手机验证码
      authCode: "",
      // code id
      codeID: "",
      verif_seconds: 60,
      timer: null,
      unionid: "",
      isinp_box: false,
      InvitationCode: "",
    };
  },
  created() {
    if (this.$route.query.InvitationCode) {
      this.InvitationCode = this.$route.query.InvitationCode;
    }
  },
  mounted() {
    this.getQueryVariable();
  },
  beforeRouteEnter(to, from, next) {
    console.log(from);
    next();
  },
  methods: {
    // 获取微信登录code
    getQueryVariable() {
      var query = window.location.href;

      if (query.includes("code=")) {
        this.code = query.split("code=")[1].split("&state")[0];
        // this.code = query.split("code=")[1];
        console.log(this.code);
        this.wxVerify(this.code);
      }
    },

    // 发送验证码
    async sendVerif() {
      if (this.timer != null) {
        return;
      }
      let reg = /^1[0-9]{10}$/;
      if (!reg.test(this.phone)) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号!",
        });
        return;
      }

      const data = {
        type: 5,
        mobile: this.phone,
      };
      this.timer = setInterval(() => {
        if (this.verif_seconds > 0) {
          this.verif_seconds--;
        } else {
          clearInterval(this.timer);
          this.verif_seconds = 60;
          this.timer = null;
        }
      }, 1000);
      const res = await getMobileAchCaptchas(data);
      if (res.code != 200) {
        this.$message({
          type: "error",
          message: res.message,
        });
        return;
      }

      this.codeID = res.data.codeId;
    },

    // 绑定
    async binding() {
      if (!this.phone.trim()) {
        this.$message({
          type: "error",
          message: "请输入手机号",
        });
        return;
      }
      if (this.codeID == "") {
        this.$message({
          type: "error",
          message: "请获取验证码",
        });
        return;
      }
      if (!this.authCode.trim()) {
        this.$message({
          type: "error",
          message: "请输入验证码",
        });
        return;
      }

      const data = {
        mobile: this.phone,
        codeId: this.codeID,
        code: this.authCode,
      };

      const res = await checkMobileAchCaptchas(data);
      if (res.code != 200) {
        this.$message({
          type: "error",
          message: res.message,
        });

        return;
      }

      this.weixinlogin();
    },

    // 认证登录
    async weixinlogin() {
      const data = {
        grant_type: "wechat_open",
        scope: "all",
        unionid: this.unionid,
        telephone: this.phone,
        inviteCode: this.InvitationCode,
      };
      try {
        const res = await weixinLogin(qs.stringify(data));
        Cookies.set("auth_token", JSON.stringify(res), {
          expires: 7,
          path: "/",
          domain: ".writemall.com",
        });
        this.$router.push("/infoCompileIndex");
        // this.$bus.$emit("getNav");
        this.$bus.$emit("changeNavIndex", "文库");
        this.getUserinfo();
        setTimeout(() => {
          window.location.reload(true);
        }, 300);
      } catch (error) {
        this.$message.error("发现意外错误,请换其他登录方式");
      }
    },

    // code校验
    async wxVerify(code) {
      const data = {
        code,
        platform: 2,
      };

      const res = await verify(qs.stringify(data));

      // console.log(res);
      if (res.code == 2024) {
        if (res.data.unionid) {
          this.unionid = res.data.unionid;
          this.isinp_box = true;
        } else {
          this.$router.go(-1);
          this.$message.error("code已经失效,请重新扫码!");
          window.loginFun();
        }
        return;
      }

      if (res.code != 200) {
        this.$router.go(-1);
        this.$message.error("发现意外错误,请换其他登录方式");
        window.loginFun();
        return;
      }

      this.unionid = res.data.unionid;

      this.weixinlogin();
    },

    //获取用户信息
    async getUserinfo() {
      let res = await getuserInfo();
      if (res.code === 200) {
        let userData = res.data;
        this.$store.commit({
          type: "SET_USER_INFO",
          userInfo: userData,
        });
        this.$store.dispatch("CHECK_REAL_NAME");
      } else {
        this.$message.error(res.message);
      }
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => {
      console.log("销毁");
    });
  },
};
</script>

<style lang="scss" scoped>
.weixin_login {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;

  .box {
    display: flex;
    flex-direction: column;
    width: 1338px;
    margin: 0 auto;
    height: calc(100vh - 150px);
    background-color: #fff;
    position: relative;
  }

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }

  .top {
    border-top: 1px solid #f1f3f4;
    padding: 10px 20px;
    .back {
      width: 5%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999999;
      img {
        margin-right: 5px;
        height: 8.8px;
      }
    }
  }
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .weixin_logo {
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);
      margin-bottom: 66px;
      width: 210px;
    }
    .box {
      // text-align: center;
      padding: 240px 0 150px;
      box-sizing: border-box;
      align-items: center;
      // margin: 20vh 0;
      .title {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        font-family: PingFang SC;
        font-weight: bold;
        // margin-bottom: 30px;
      }
      .inp_box {
        box-sizing: border-box;
        margin-bottom: 18px;
        width: 298px;
        height: 43px;
        background: #f8f8f8;
        border-radius: 22px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        img {
          width: 16px;
          margin-right: 20px;
        }
        input {
          width: 100%;
          height: 100%;
          background-color: #f8f8f8;
        }

        .span {
          width: 110px;
          border-left: 1px solid #e8e8e8;
          font-size: 12px;
          cursor: pointer;
          padding: 5px 0 5px 10px;
          color: #ff6900;
        }
      }

      .btn {
        cursor: pointer;
        margin-top: 30px;
        width: 298px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        color: #fff;
        background: #4587ff;
        border-radius: 22px;
      }
    }
  }
}
</style>
